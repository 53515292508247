let paramUtil = {
  clearEmpty: param => {
    const result = {};
    for (let key of Object.keys(param)) {
      const value = param[key];
      if (
        value !== undefined &&
        value !== null &&
        value !== "" &&
        value !== "null"
      ) {
        result[key] = value;
      }
    }
    return result;
  }
};
export default paramUtil;
