/**
 * 年月日格式化，年月日小时分钟秒 截取为年月日
 * @param value
 * @returns {string|*}
 */
export const splitDate = value => {
  if (value) {
    return value.substring(0, 10);
  }
  return value;
};

export const splitTime = value => {
  if (value) {
    return value.substring(11, 19);
  }
  return value;
};

export const splitDateTime = value => {
  if (value) {
    return value.substring(0, 19).replace('T', ' ');
  }
  return value;
};

/**
 * 给指定时间增加1年
 * @param value:日期
 * @returns {string|*}
 */
export const addYearDate = value => {
  let date = new Date(value);
  date.setFullYear(date.getFullYear() + 1);

  const year = date.getFullYear().toString();
  const month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  return year + "-" + month + "-" + day;
};

/**
 * 根据生日计算年龄
 * @param value
 * @returns {string|number}
 */
export const toAge = value => {
  if (!value) {
    return "暂未设置";
  }
  //出生时间 毫秒
  const birthDayTime = new Date(value).getTime();
  //当前时间 毫秒
  const nowTime = new Date().getTime();
  //一年毫秒数(365 * 86400000 = 31536000000)
  return Math.ceil((nowTime - birthDayTime) / 31536000000);
};

export const parseTime = value => {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  return `${date.getFullYear()}-${appendZero(date.getMonth() + 1)}-${appendZero(
    date.getDate()
  )} ${appendZero(date.getHours())}:${appendZero(date.getMinutes())}`;
};

export const parseDate = value => {
  if (!value) {
    return "";
  }
  const date = new Date(value);
  return `${date.getFullYear()}-${appendZero(date.getMonth() + 1)}-${appendZero(
    date.getDate()
  )}`;
};

export const parseUnitTime = value => {
  if (!value) {
    return "";
  }
  let minutes = value / 1000 / 60;
  let minutesRound = Math.floor(minutes);
  let seconds = Math.floor(value / 1000 - (60 * minutesRound));
  return `${minutesRound}分${seconds}秒`;
};

function appendZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

// 转换成两位小数，不足则补充0
export const towFixNumber = num => {
  if (!num) {
    return "";
  }
  // 判断是否为数字类型   数字类型自动  舍0
  if(typeof(num) == 'number'){
    // 先转成  字符串类型
    num = num.toString()
  }
  // 判断 有无小数点  0 表示 有小数点  -1  表示没有小数点
  if(num.indexOf(".") !== -1){
    // 根据小数点  转换字符串为数组
    let fixArray = num.split('.')
    // 判断 有几位小数  如果有一位  加一个0
    if(fixArray[1].length === 1){
      fixArray = fixArray.join('.')
      fixArray += '0'
      num = fixArray
    }
  }else {
    num += '.00'
  }
  return num;
}
//转换成两位小数的百分比
export const percentNumber = num => {
	if(num !== 0){
		let number = parseFloat((num * 100).toFixed(2)) + '%'
		return number;
	}
	if(num == 0){
		return num;
	}
}
