import CryptoJS from 'crypto-js/crypto-js'

const KEY_STR="78471171a6748b08518ef6ccc62e1f24";

 //加密
export function encrypt(word) {
	var keyStr = KEY_STR; //判断是否存在ksy，不存在就用定义好的key
	var key = CryptoJS.enc.Utf8.parse(keyStr);
	var srcs = CryptoJS.enc.Utf8.parse(word);
	var encrypted = CryptoJS.AES.encrypt(srcs, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
	return encrypted.toString();
}

//解密
export function decrypt(word) {
	var keyStr = KEY_STR;
	var key = CryptoJS.enc.Utf8.parse(keyStr);
	var decrypt = CryptoJS.AES.decrypt(word, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
	return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}