<template>
	<div id="app" @mousemove="moveEvent" @click="moveEvent"><router-view /></div>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			timmer: null
		};
	},
	metaInfo() {
		return {
			meta: [
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
				}
			]
		};
	},
	methods: {
		moveEvent() {
			let path = ['/login'];
			if (!path.includes(this.$route.path)) {
				clearTimeout(this.timmer);
				this.init();
			}
		},
		init() {
			// 30分钟清空session回到登录页面
			this.timmer = setTimeout(() => {
				sessionStorage.removeItem('sessionData');
				localStorage.clear();
				sessionStorage.clear();
				//this.$cache.reset();
				this.$router.push({
					path: '/login'
				});
			},30*60*1000);
		}
	}
};
</script>

<style lang="stylus">
#app
	font-family Avenir, Helvetica, Arial, sans-serif
	-webkit-font-smoothing antialiased
	-moz-osx-font-smoothing grayscale
	/* text-align center */
	color #2c3e50
	height 100%
	background-color #EFEFEF
	min-width 1360px
	/* margin-top 60px */
html, body
	height 100%
	width 100%
	margin 0
body .el-table th.gutter
	display table-cell !important
body .el-table colgroup.gutter
	display table-cell !important
</style>
