let routerUtil = {
  setParam: value => {
    sessionStorage.setItem("_routerParam", JSON.stringify(value));
  },
  getParam: () => {
    return JSON.parse(sessionStorage.getItem("_routerParam"));
  },
  setParamStr: value => {
    sessionStorage.setItem("_routerParam", value);
  },
  getParamStr: () => {
    sessionStorage.getItem("_routerParam");
  },
  clearParam: () => {
    sessionStorage.removeItem("_routerParam");
  },
  setCondition: value => {
    sessionStorage.setItem("_routerCondition", JSON.stringify(value));
  },
  getCondition: () => {
    return JSON.parse(sessionStorage.getItem("_routerCondition"));
  },
  setConditionStr: value => {
    sessionStorage.setItem("_routerCondition", value);
  },
  getConditionStr: () => {
    sessionStorage.getItem("_routerCondition");
  },
  clearCondition: () => {
    sessionStorage.removeItem("_routerCondition");
  },
  claerAll: () => {
    sessionStorage.removeItem("_routerParam");
    sessionStorage.removeItem("_routerCondition");
  }
};
export default routerUtil;
