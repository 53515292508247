let setFormUtil = {
  setForm: (targetForm, sourceForm) => {
    Object.keys(targetForm).forEach(targetKey => {
      Object.keys(sourceForm).forEach(sourceKey => {
        if (targetKey === sourceKey) {
          targetForm[targetKey] = sourceForm[sourceKey];
        }
      });
    });
    return targetForm;
  }
};
export default setFormUtil;
